import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import {
  PAGES,
  ROUTES,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
} from "../../utils/constants";
import { callTrackingApi } from "../../utils/helperCookie";
import "./Pricing.css";
import PricingCard from "./PricingCard";
import { getCurrencyFreemium } from "../../utils/utils";

const Pricing = () => {
  const { pathname } = useLocation();
  const [country, setCountry] = useState("");
  const emailId = useSelector((state) => state?.users?.user?.attributes?.email);
  const prices = useSelector((state) => state?.prices?.priceTable);
  const status = useSelector((state) => state?.stage?.aboutYou?.StatusVal);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const isFirsTimeUser = useSelector((state) => state?.users?.firstTimeUser);
  const hubspotYou = useSelector((state) => state?.stage?.hubspotYou);
  const hubspotUser = useSelector((state) => state?.users?.hubspotUser);
  const [priceVarUpdate, setPriceVarUpdate ] = useState(false); 

  useEffect(() => {
    if (emailId) {
      callTrackingApi({
        email: emailId,
        pagetitle: PAGES.PRICING_PLAN,
        pagelink: pathname,
      });
    }
  }, [emailId]);

  useEffect(() => {
    if (hubspotYou?.Country && hubspotYou?.Country?.toLowerCase() === "india") {
      setCountry("inr");
      console.log("inr");
    } else {
      setCountry("usd");
      console.log("usd");
    }
  }, [hubspotYou]);
  

  const showPaymentText = () => {
    if (status === STATUS_PAYMENT_AWAITED) {
      return (
        <Typography
          m={1}
          p={1}
          fontSize={"18px"}
          fontWeight={400}
          variant="subtitle1"
          color="initial"
          sx={{
            background: "#E0FFD5",
            borderRadius: "4px",
            width: "100%",
          }}
        >
          We've just sent you an email with all the payment details you need.
          Please check your inbox, and if you don't see it, don't forget to peek
          in your spam folder. As soon as we confirm your payment, we will share
          a link to download CARBN.AI<sup>TM</sup>. If you have any questions or
          need assistance, feel free to reach out to our support team at
          support@carbn.ai.
        </Typography>
      );
    } else if (status === STATUS_PAYMENT_FAILED) {
      return (
        <Typography
          m={1}
          p={1}
          fontSize={"18px"}
          fontWeight={400}
          variant="subtitle1"
          color="initial"
          sx={{
            background: "#E0FFD5",
            borderRadius: "4px",
            width: "100%",
            textAlign: "center",
          }}
        >
          Oops! Your payment attempt has failed. Please check your details and
          try again.
        </Typography>
      );
    }
  };


  return (
    <>
      <div className={`container ${pathname === "/app-stage" ? "" : ""}`}>
        <div className="stage-main-box">
          {pathname !== ROUTES.PRICING &&
            (hubspotUser === "0" || hubspotUser === 0) && (
              <div className="verified-text">
                <label>
                  <span>
                    <CheckCircleOutlineOutlinedIcon className="check-circle" />
                    &nbsp;
                  </span>
                  Great! Your document is verified. 
                  {/* <NavLink className={"navLink"} to={ROUTES.DASHBOARD}>
                    <u>'Your Profile'</u>
                  </NavLink> */}
                </label>
              </div>
            )}
          {showPaymentText()}
          <div className="blackBG">
            <div className="price-title">
              <h4>Pick your subscription</h4>
              <p>Choose what works best for you</p>
            </div>
            <div className="price-col-box">
              {prices.map((el) => {
                console.log(el);
                let item = "";
                // firsttimeuser = true // 4 with freemium
                // firsttimeuser = false, 3 cards
                // if (country === el?.currency) {
                //   item = (
                //     <PricingCard
                //       Id={el?.id}
                //       title={el?.metadata?.Name}
                //       price={(el?.unit_amount / 100).toFixed(2)}
                //       decription={el?.metadata?.Description}
                //       dataLimit={el?.metadata?.DataLimit}
                //       timeLimit={el?.metadata?.TimeLimit}
                //       currentPlan={el?.currentPlan}
                //       planName={el?.metadata?.Name}
                //       priceId={el?.id}
                //       priceIdINR={el?.id}
                //       key={el?.id}
                //       status={status}
                //       isFirsTimeUser={isFirsTimeUser}
                //       currency={el?.currency}
                //     />
                //   );
                // }
                if (
                  aboutYou?.FirstTimeUser &&
                  el?.metadata?.Name === "Freemium"
                ) {
                  item = (
                    <PricingCard
                      Id={el?.id}
                      title={el?.metadata?.Name}
                      price={(el?.unit_amount / 100).toFixed(2)}
                      decription={el?.metadata?.Description}
                      dataLimit={el?.metadata?.DataLimit}
                      timeLimit={el?.metadata?.TimeLimit}
                      currentPlan={el?.currentPlan}
                      planName={el?.metadata?.Name}
                      priceId={el?.id}
                      priceIdINR={el?.id}
                      key={el?.id}
                      status={status}
                      isFirsTimeUser={isFirsTimeUser}
                      currency={getCurrencyFreemium(country, 0)}
                    />
                  );
                }else if(el?.metadata?.Name != "Freemium"){
                  item = (
                    <PricingCard
                      Id={el?.id}
                      title={el?.metadata?.Name}
                      price={(el?.unit_amount / 100).toFixed(2)}
                      decription={el?.metadata?.Description}
                      dataLimit={el?.metadata?.DataLimit}
                      timeLimit={el?.metadata?.TimeLimit}
                      currentPlan={el?.currentPlan}
                      planName={el?.metadata?.Name}
                      priceId={el?.id}
                      priceIdINR={el?.id}
                      key={el?.id}
                      status={status}
                      isFirsTimeUser={isFirsTimeUser}
                      currency={el?.currency}
                    />
                  );
                }
                if (
                  !aboutYou?.FirstTimeUser &&
                  el?.metadata?.Name === "Freemium"
                ) {
                  item = "";
                }
                return item;
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Pricing;
