import { Box } from "@mui/material";
import React from "react";
import { CODE_ERROR } from "../../utils/constants";
import SnackBarHome from "../Snackbar/SnackbarHome";

const SignupStageOne = ({
  user,
  userError,
  handleChange,
  handleCodeSubmit,
  handleCodeResend,
  open,
  setOpen,
  message,
}) => {
  return (
    <div>
      <h3 className="reset-title">We Have Emailed You</h3>
      <p className=" mb-4">
        Your OTP is on the way. To log in, enter the OTP we emailed to{" "}
        {user?.email}. It may take a minute to arrive.
      </p>

      <div className="field-col full-width">
        <label className="field-title">
          Enter your OTP<span>*</span>
        </label>
        <input
          className="field-input-login"
          name="code"
          type="text"
          required
          value={user?.code}
          onChange={handleChange}
        />
        <span className="error-message">
          {userError?.codeError ? CODE_ERROR : ""}
        </span>
      </div>
      <SnackBarHome
        open={open}
        setOpen={setOpen}
        severity="error"
        message={message}
      />
      <button
        className="btn primary-btn me-3 mb-3"
        onClick={(event) => {
          handleCodeSubmit(event);
        }}
      >
        Confirm
      </button>
      <button
        className="btn primary-border-btn mb-3"
        onClick={(event) => {
          handleCodeResend(event);
        }}
      >
        Resend OTP
      </button>
    </div>
  );
};

export default SignupStageOne;
