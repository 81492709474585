import { Box } from "@mui/material";
import React from "react";
import { EMAIL_ERROR } from "../../utils/constants";
import SnackBarHome from "../Snackbar/SnackbarHome";

const ResetPasswordStageOne = ({
  setForgotPasswordStage,
  user,
  userError,
  handleChange,
  handleForgotPassword,
  open,
  setOpen,
  message,
}) => {
  return (
    <>
      <h3 className="reset-title mb-4">Reset your password</h3>

      <div className="field-col full-width">
        <label className="field-title">Enter your email<span>*</span></label>
        <input className="field-input-login" name="verifyEmail" type="text" required value={user?.verifyEmail} onChange={handleChange} />
        <span className="error-message">{userError?.verifyEmailError ? EMAIL_ERROR : ""}</span>
      </div>


      <SnackBarHome
        open={open}
        setOpen={setOpen}
        severity="error"
        message={message}
      />
      
      <button className="btn primary-btn me-3 mb-3" onClick={(event) => {handleForgotPassword(event);}} disabled={!user?.verifyEmail} >
        Send OTP
      </button>
      <button className="btn primary-border-btn mb-3" onClick={(event) => { setForgotPasswordStage(0); }} >
        Back to Sign in
      </button>
    </>
  );
};

export default ResetPasswordStageOne;
