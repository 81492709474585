import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ApiService from "../service/axiosUtils";
import { freemiumObj, priceData } from "../utils/constants";
import { handlePriceTable } from "../utils/utils";

const slice = createSlice({
  name: "prices",
  initialState: {
    prices: priceData,
    priceTable: [],
    updatePrice: "",
  },
  reducers: {
    setPrices: (state, action) => {
      state.prices = action.payload;
    },
    setPriceTable: (state, action) => {
      state.priceTable = action.payload;
    },
    updatePlanName: (state, action) => {
      state.updatePrice = action.payload;
    },
  },
});

export default slice.reducer;

export const { setPrices, updatePlanName, setPriceTable } = slice.actions;

export const getPricingTable = (tokenId) => async (dispatch) => {
  // console.log(process.env.REACT_APP_PROD_BASE_URL);
  // console.log("env checking");

  if(process.env.REACT_APP_PROD_BASE_URL=='https://api.dev.carbn.ai/carbon-dev/'){
    var responseData = [
        {
          "id": "price_1Op9LLSI7RoV16Ymj36K66em",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1709212091,
          "currency": "inr",
          "custom_unit_amount": null,
          "livemode": false,
          "lookup_key": null,
          "metadata": {
            "DataLimit": "2 GB",
            "Description": "Allow users to access a basic version of the Carbon for free. The free version serves as a gateway for users to understand the core value of the product without financial commitment. User to be qualified by at least a DSP level letter..",
            "Feature list": "Data Limit: Upto 2 GB, Time Limit: 1 month",
            "Name": "Freemium",
            "TimeLimit": "1M"
          },
          "nickname": "Freemium",
          "product": "prod_PGu9S49oqgrcsW",
          "recurring": null,
          "tax_behavior": "unspecified",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 0,
          "unit_amount_decimal": "0"
        },
        {
          "id": "price_1ObeTtSI7RoV16Ymb3iuxadS",
          "object": "price",
          "active": true,
          "billing_scheme": "per_unit",
          "created": 1705995193,
          "currency": "inr",
          "custom_unit_amount": null,
          "livemode": false,
          "lookup_key": null,
          "metadata": {
            "Description": "To learn more about our pricing plans and find the best solution tailored to your needs, please contact our sales team. We're here to provide you with all the information and support you need to make an informed decision.",
            "Name": "Adaptive Growth",
          },
          "nickname": "Used for domestic payment",
          "product": "prod_PGuHevg850rkVl",
          "recurring": null,
          "tax_behavior": "unspecified",
          "tiers_mode": null,
          "transform_quantity": null,
          "type": "one_time",
          "unit_amount": 17699882,
          "unit_amount_decimal": "17699882"
        },
      ];

      const data = responseData || [];
      dispatch(setPriceTable(data));

  }else{
    try {
      await ApiService.getApiData(
        `${process.env.REACT_APP_PROD_BASE_URL}/payment/pricingTable`,
        tokenId
      ).then((response) => {
        console.log(response);
        console.log("stripe response on here");
        const data = response?.data || [];
        dispatch(setPriceTable(data));
      });
    } catch (e) {
      console.log(e?.response);
      if (e?.response?.status !== 400) {
        toast.error(e?.message, {
          position: "top-center",
        });
      }
    }
  }
};
