import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ADMIN_VERIFIED,
  STATUS_DOWNLOADABLE,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
  STATUS_PAYMENT_SUCCESS,
  STATUS_PENDING_VERIFICATION,
  STATUS_REGISTERED,
  STATUS_VERIFICATION_COMPLETED,
  STATUS_VERIFICATION_FAILED,
} from "../../utils/constants";
import { getRouteHeading, getRouteName } from "../../utils/utils";

const NotificationStrip = ({ userData, status }) => {
  const [barVisible, setBarVisible] = useState("none");

  useEffect(() => {
    getNotificationVisible();
  }, [userData, status]);

  const getNotificationVisible = () => {
    if (
      window.location.pathname === "/app-stage" &&
      status === STATUS_DOWNLOADABLE
    ) {
      setBarVisible("block");
    } else if (
      window.location.pathname === "/download" &&
      (status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS)
    ) {
      setBarVisible("none");
    }else if (
      window.location.pathname === "/profile" &&
      (status === STATUS_DOWNLOADABLE || status === STATUS_PAYMENT_SUCCESS)
    ) {
      setBarVisible("none");
    } else if(window.location.pathname === "/how-to-begin"){
      setBarVisible("none");
    } else if (
      userData &&
      userData?.activeUser &&
      userData?.eventDay === "false" &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/app-stage" &&
      (status === ADMIN_VERIFIED ||
        status === STATUS_REGISTERED ||
        status === STATUS_PENDING_VERIFICATION ||
        status === STATUS_VERIFICATION_FAILED ||
        status === STATUS_VERIFICATION_COMPLETED ||
        status === STATUS_PAYMENT_FAILED ||
        status === STATUS_PAYMENT_AWAITED ||
        status === STATUS_PAYMENT_SUCCESS ||
        status === STATUS_DOWNLOADABLE)
    ) {
      setBarVisible("block");
    } else {
      setBarVisible("none");
    }
  };
  return (
    <>
      <div id="notificationstrip-id" className="notificationstrip" style={{ display: barVisible }}>
        Click on the <NavLink to={getRouteName(status)}>{getRouteHeading(status)}</NavLink> to complete your purchase journey.
        {/* <NavLink to={getRouteName(status)}>{getRouteHeading(status)}</NavLink>' */}
         
      </div>
    </>
  );
};

export default NotificationStrip;
