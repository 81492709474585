import React, { useState, useEffect } from 'react';

const Version = () => {
  const [showVersion, setShowVersion] = useState(false);

  useEffect(() => {
      
    const handleKeyDown = (event) => {
      // Toggle version display on Ctrl+Shift+V
      if (event.ctrlKey && event.shiftKey && event.key === 'V') {
        setShowVersion(!showVersion);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };

  }, [showVersion]);


  return (
    showVersion && (
      <>
      <div className="version-info" style={{ position: 'fixed', bottom: '10px', right: '10px', backgroundColor: '#000', color : '#fff' , padding: '5px', border: '1px solid #000' }}>
        <span>App Version: {process.env.REACT_APP_VERSION}</span>
      </div>
      </>
    )
  );
};

export default Version;