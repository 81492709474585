import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPrices } from "../../redux/prices";
import ApiService from "../../service/axiosUtils";
import {
  ROUTES,
  STATUS_PAYMENT_AWAITED,
  STATUS_PAYMENT_FAILED,
} from "../../utils/constants";
import LoadingScreen from "../LoadingScreen";
import "./Pricing.css";
import {
  dealStageAdminHubspot,
  getHubspotUser,
} from "../../service/hubspotAxios";
import { getCurrency, getTimeLimitMonth } from "../../utils/utils";

const PricingCard = ({
  Id,
  title,
  price,
  decription,
  dataLimit,
  timeLimit,
  currentPlan,
  planName,
  priceId,
  status,
  isFirsTimeUser,
  priceIdINR,
  currency,
}) => {
  const prices = useSelector((state) => state?.prices?.prices);
  const userDetails = useSelector((state) => state?.users?.user);
  const aboutYou = useSelector((state) => state?.stage?.aboutYou);
  const attributes = useSelector((state) => state?.users?.user?.attributes);
  const FirsTimeUser = useSelector((state) => state?.users?.firstTimeUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const setFreemiumPlan = () => {
    if (aboutYou?.FirstTimeUser === false && !aboutYou?.ContractS3Key) {
      navigate(ROUTES.APP_STAGE);
      return;
    }
    dispatch(setPrices(prices));
    if (planName === "Freemium") {
      // CALL DEAL API
      const p = getHubspotUser(attributes?.email);
      p.then((r) => r.json()).then(() => {
        const payload = {
          dealname: attributes?.given_name + " " + attributes?.family_name,
          dealstage: "150835226",
        };
        //dealStageAdminHubspot(payload, attributes?.email);
      });

      setLoading(true);
      const tokenId = userDetails.token;
      const userID = userDetails.username;
      ApiService.getApiData(
        `${
          process.env.REACT_APP_PROD_BASE_URL
        }/payment/freemium?Amount=${getCurrency(
          currency,
          price
        )}&DataLimit=${dataLimit}&TimeLimit=${timeLimit}&PlanName=${planName}`,
        tokenId
      )
        .then((response) => {
          setLoading(false);
          if (response?.status == 200) {
            // navigate(ROUTES.DOWNLOAD);
            navigate(ROUTES.FREEMIUM_SUCCESS, {
              state: {
                status: "freemium",
              },
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.message, {
            position: "top-center",
          });
        });
    } else {
      navigate(ROUTES.PAYMENTDETAIL, {
        state: {
          Id,
          title,
          price,
          decription,
          dataLimit,
          timeLimit,
          planName,
          priceId,
          priceIdINR,
        },
      });
    }
  };

  console.log(title);
  console.log("titleeeee");
  return (
    <>
      <div className="price-col config-changes"> 

        <h4>{title}</h4>
        <div className="price">{getCurrency(currency, price)}</div>
        <p>{decription}</p>
        <ul>
          {dataLimit && <li>{dataLimit}</li>}
          {timeLimit && <li>{getTimeLimitMonth(timeLimit)}</li>}
        </ul>

        {planName=="Freemium" 
        ? 
        <button
          type="button"
          className="btn secondary-btn"
          disabled={
            // currentPlan ||
            // status === STATUS_PAYMENT_FAILED ||
            status === STATUS_PAYMENT_AWAITED
            // isFirsTimeUser === true
          }
          onClick={setFreemiumPlan}
        >
          {aboutYou?.Plan == planName ? "Current Plan" : "Select Plan"}
        </button> 
        : 
          <a href="mailto:info@clear-trail.com?subject=Request for Custom Pricing Plan for CARBN.AI™&body=Dear Team,%0D%0A%0D%0AI am reaching out to express my interest in the custom pricing plans for CARBN.AI™.%0D%0A%0D%0AI am particularly interested in understanding how these plans can be aligned with our specific needs.%0D%0A%0D%0ABest regards," className="btn secondary-btn">Talk to Sales</a> 
         }


        {loading && <LoadingScreen open={loading} />}
      </div>
    </>
  );
};

export default PricingCard;
